import Swal from "sweetalert2";
import { config } from "../config.js";
import { post, get } from './baseApi.service.js';

export const apiGetSetting = async () => {
  try {
    const result = await get("/api/setting", {site: config.site});
    if (result?.status_code === 200) {
      return result;
    }
  } catch (error) {
    return Swal.fire({ icon: "error", text: "Lỗi kết nối, vui lòng thử lại sau." });
  }
};

export const apiCheckAccount = async (userName) => {
  let raw = { account: userName };
  try {
    const result = await post("/api/user/check",raw);
    if (result?.code === 200) {
      return result;
    }
  } catch (error) {
    return Swal.fire({ icon: "error", text: "Thao tác lỗi, vui lòng thử lại sau." });
  }
};

export const apiFormUpdateAccount = async (body, files) => {
  try {
    const formData = new FormData();

    // Thêm dữ liệu từ body vào formData
    for (const key in body) {
      formData.append(key, (typeof body[key] === 'object') ? JSON.stringify(body[key]) : body[key] );
    }

    // Thêm files vào formData (nếu có)
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append("files", file);
      });
    }
    const result = await post("/api/user/form-info-update",formData);
    if (result.code === 200) {
      return true;
    } else {
      Swal.fire({
        icon: "error",
        title: result.error,
        text: result.message,
      });
      return false;
    }
  } catch (error) {
    console.error("error", error);
  }
};

export const apiUpdateAccount = async (body) => {
  let raw = body;
  try {
    const result = await post("/api/user/info-update",raw);
    if (result.code === 200) {
      return true;
    } else {
      Swal.fire({
        icon: "error",
        title: result.error,
        text: result.message,
      });
      return false;
    }
  } catch (error) {
    console.error("error", error);
  }
};

export const apiCheckDataAccount = async (body) => {
  let raw = body;
  try {
    const result = await post("/api/user/check-data-old",raw);
    if (result.code === 200) {
      return result.data;
    } else {
      if(result.code === 202) {
        Swal.fire({
          icon: "error",
          title: result.error,
          text: result.message,
        });
      }
      return false;
    }
  } catch (error) {
    console.error("error", error);
  }
};

export const apiConfirmUpdate = async (body) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify(body);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  try {
    const response = await fetch(`${config.BE_API_URL}/api/user/confirm-update`, requestOptions);
    const result = await response.json();
    if (result.code === 200) {
      return result.data;
    } 
  } catch (error) {
    console.error("error", error);
  }
};