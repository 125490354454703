export const config = {
  api_local: process.env.REACT_APP_API_URL, 
  site: process.env.REACT_APP_SITE, 
  url_789bet: "789bet",
  BE_API_URL: process.env.REACT_APP_API_URL,
  LIST_BANK_NAME: [
    "ABBANK",
    "Agribank",
    "ACB",
    "BacABank",
    "BaoVietBank",
    "BIDV",
    "CAKE",
    "CB",
    "CIMB",
    "Co-opBank",
    "COOPBANK",
    "DongA Bank",
    "Eximbank",
    "GPBank",
    "HD BANK",
    "HSBC",
    "IVB",
    "K-BANK",
    "KienLongBank",
    "LienVietPostBank, LPB",
    "LioBank",
    "Maritime Bank, MSB",
    "Military Bank, MBB",
    "Nam A Bank",
    "National Citizen Bank, NCB",
    "Oceanbank",
    "Orient Commercial Bank, OCB",
    "PG bank",
    "Public Bank Berhad",
    "PVcom Bank",
    "Sacombank",
    "Saigonbank, SGB",
    "SCB",
    "SeABank",
    "SHBank, SHB",
    "SHINHAN BANK",
    "Techcom Bank",
    "TIMO BANK",
    "TPBank",
    "UBANK",
    "UNITED OVERSEAS BANK- UOB",
    "VBSP",
    "VIBBank, VIB",
    "VietABank, VAB",
    "Vietbank, VB",
    "VietCapitalBank",
    "Vietcombank",
    "Vietin Bank",
    "VPBank",
    "VRB (VIET NGA)",
    "WOORIBANK"
  ],
};